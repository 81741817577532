(function(){

	var mobileToggle = $(".js-mobile-toggle");
	var mobileToggleIcon = $(".js-mobile-toggle-button");
	var mobileMenu = $(".js-mobile-navigation");

	mobileToggle.click(function() {
		mobileToggle.toggleClass('is-open');
		mobileMenu.slideToggle();
	});

  var videoPlay = $(".js-video-play-logo");
  var overlay = $(".js-overlay");
  var overlayVideo = $(".js-overlay-content");
  var closeOverlayElements = $(".js-overlay, .js-overlay-close");


  videoPlay.click(function() {
    overlay.css('display', 'block');
    overlayVideo.css('display', 'block');
  });


  closeOverlayElements.click(function() {
    $(this).css('display', 'none');
    overlayVideo.css('display', 'none');
  });


	var scrollUp = $(".js-scroll-up");

	scrollUp.click(function() {
		$("html, body").animate({ scrollTop: 0 }, 1000);
		return false;
	});




	(function(){
		var sliderPrev = $(".js-slider-prev");
		var sliderNext = $(".js-slider-next");
		var sliderHexRotation = 0;
		var sliderImage = $(".js-slider-image");
		var amountOfImages = sliderImage.length;

		sliderNext.click(function(){

			var currentSlide = sliderImage.filter('.visible').removeClass('visible');
			var currentIndex = sliderImage.index(currentSlide) + 1;

			if (currentIndex === amountOfImages) {
				sliderImage.first().addClass('visible');
				return;
			}
			currentSlide.next('.js-slider-image').addClass('visible');
		});


		sliderPrev.click(function(){

			var currentSlide = sliderImage.filter('.visible').removeClass('visible');
			var currentIndex = sliderImage.index(currentSlide) + 1;

			if (currentIndex === 1) {
				sliderImage.last().addClass('visible');
				return;
			}
			currentSlide.prev('.js-slider-image').addClass('visible');
		});

	})();



	var imagesToAnimateIn = $(".art-examples__block");
	var imageRow = $(".art-examples__row");


	if (imageRow.length){

		$(window).scroll(function(){

			if($(this).scrollTop() > imageRow.offset().top - ($(window).height() / 1.25)) {
				imagesToAnimateIn.each(function(i) {
					setTimeout(function(){
						imagesToAnimateIn.eq(i).addClass('is-showing');
					}, 150 * (i+1));
				});
			}
		});
	} 





	var workToAnimateIn = $(".work-examples__box");
	var workRow = $(".work-examples__row");


	if (workRow.length){

		$(window).scroll(function(){

			if($(this).scrollTop() > workRow.offset().top - ($(window).height() / 1.25)) {
				workToAnimateIn.each(function(i) {
					setTimeout(function(){
						workToAnimateIn.eq(i).addClass('is-showing');
					}, 150 * (i+1));
				});
			}
		});
	} 




	var icons = $(".cost-block__icon, .floating-feature__icon");

	icons.addClass('is-showing');





	// Hide Header on on scroll down
	var didScroll;
	var lastScrollTop = 0;
	var delta = 5;
	var toHideOnMobile = $('.global-logo, .mobile-header__contact-row');
	var globalNavHeight = $('.global-header').outerHeight();

	$(window).scroll(function(event){
	    didScroll = true;
	});

	setInterval(function() {
	    if (didScroll) {
	        hasScrolled();
	        didScroll = false;
	    }
	}, 250);

	function hasScrolled() {
	    var st = $(this).scrollTop();
	    
	    // Make sure they scroll more than delta
	    if(Math.abs(lastScrollTop - st) <= delta || $(window).width() >= 1050)
	        return;
	    
	    // If they scrolled down and are past the globalLogo, add class .nav-up.
	    // This is necessary so you never see what is "behind" the globalLogo.
	    if (st > lastScrollTop && st > globalNavHeight){
	        // Scroll Down
	        toHideOnMobile.slideUp();
	    } else {
	        // Scroll Up
	        if(st + $(window).height() < $(document).height()) {
	            toHideOnMobile.slideDown();
	        }
	    }
	    
	    lastScrollTop = st;
	}







	var tabNavs = $(".js-tabbed-nav-item");
	var tabContent = $(".js-tabbed-content");


	tabNavs.click(function() {
		tabNavs.removeClass('active');
		$(this).addClass('active');
		console.log("I have been clicked");
		var navigationNumber = $(this).data("tabnumber");

		console.log(navigationNumber);

		var currentTab = tabContent.filter(":visible");
		currentTab.hide();

		var newTab = $(".js-tabbed-content[data-tabbedcontent='" +navigationNumber+ "']").show();

		});






})();
